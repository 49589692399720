<template>
  <div class="fpw-matchup-widget">
    <div class="tw-text-center">
      <component :is="tag" :id="headingSlug" :data-title="headingText" class="tw-text-base">
        <div class="fpw-cover-container">
          <div class="fpw-away-team" :style="{ 'background-color': `#${awayTeam.bgColor}` }"></div>
          <div class="fpw-matchup">
            <div class="tw-flex tw-flex-row tw-z-50 tw-w-full tw-justify-end">
              <div class="fpw-away-team-name tw-flex tw-flex-col tw-justify-center" :style="{ color: `#${awayTeam.textColor}` }">
                <div class="fpw-team-location tw-font-kanit">{{ awayTeam.location }}</div>
                <div class="fpw-team-nickname tw-font-kanit">{{ awayTeam.nickname }}</div>
              </div>
              <div class="fpw-away-team-logo">
                <img :src="awayTeamLogo" :alt="awayTeam.fullName" :title="awayTeam.fullName" />
              </div>
            </div>
            <div class="fpw-spacer" :style="{ width: spacerWidth }"></div>
            <div class="tw-flex tw-flex-row tw-z-50 tw-w-full tw-justify-start">
              <div class="fpw-home-team-logo">
                <img :src="homeTeamLogo" :alt="homeTeam.fullName" :title="homeTeam.fullName" />
              </div>
              <div class="fpw-home-team-name tw-flex tw-flex-col tw-justify-center" :style="{ color: `#${homeTeam.textColor}` }">
                <div class="fpw-team-location tw-font-kanit">{{ homeTeam.location }}</div>
                <div class="fpw-team-nickname tw-font-kanit">{{ homeTeam.nickname }}</div>
              </div>
            </div>
          </div>
          <div class="fpw-home-team" :style="{ 'background-color': `#${homeTeam.bgColor}` }"></div>
        </div>
      </component>
      <div v-if="withDetails" class="tw-font-kanit">
        <div class="tw-font-medium">{{ matchup.dayOfWeek }} {{ matchupDate }} ET</div>
        <div>{{ matchup.stadiumDetails.name }}</div>
        <div>{{ matchupLocation }}</div>
        <div>{{ matchupSurface }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import slugify from '@fantasypoints/slugify';
import { formatGameDate } from '@/utils';

export default {
  props: {
    matchup: {
      type: Object,
      require: true,
    },
    options: {
      type: Object,
      require: false,
    },
    awayTeam: {
      type: Object,
      required: true,
    },
    homeTeam: {
      type: Object,
      required: true,
    },
    scale: {
      type: Number,
      default: 1.0,
    },
  },
  data() {
    return {
      awayTeamLogo: null,
      homeTeamLogo: null,
    };
  },
  computed: {
    spacerWidth() {
      return `${Number(this.$props.scale) * 300}px`;
    },
    tag() {
      return `h${this.$props.options && this.$props.options.h ? this.$props.options.h : 2}`;
    },
    headingText() {
      if (this.$props.options && this.$props.options.title) return this.$props.options.title;
      return `${this.$props.awayTeam.fullName} at ${this.$props.homeTeam.fullName}`;
    },
    headingSlug() {
      if (this.$props.options && this.$props.options.title) return `h.${slugify.generate(this.$props.options.title)}`;
      return `h.${slugify.generate(this.$props.awayTeam.fullName)}-at-${slugify.generate(this.$props.homeTeam.fullName)}`;
    },
    withDetails() {
      if (this.$props.options && this.$props.options.withDetails === false) return false;
      return true;
    },
    matchupDate() {
      return formatGameDate({ date: this.$props.matchup.startDate });
    },
    matchupLocation() {
      const details = [];
      const { stadiumDetails } = this.$props.matchup;
      if (stadiumDetails.city) details.push(stadiumDetails.city);
      if (stadiumDetails.state && stadiumDetails.country === 'USA') details.push(stadiumDetails.state);
      if (!stadiumDetails.state && stadiumDetails.country) details.push(stadiumDetails.country);
      return details.join(', ');
    },
    matchupSurface() {
      const details = [];
      const { stadiumDetails } = this.$props.matchup;
      if (stadiumDetails.playingSurface) details.push(stadiumDetails.playingSurface);
      if (stadiumDetails.type) details.push(stadiumDetails.type);
      return details.join(' - ');
    }
  },
  created() {
    this.awayTeamLogo = `${this.awayTeam.logo}?size=${Number(this.$props.scale) * 500}x${Number(this.$props.scale) * 500}`;
    this.homeTeamLogo = `${this.homeTeam.logo}?size=${Number(this.$props.scale) * 500}x${Number(this.$props.scale) * 500}`;
  },
};
</script>

<style scoped>
.fpw-matchup-widget {
  margin: 0;
  padding: 0;
  background-color: #fff;
  font-size: 90%;
}
.fpw-matchup-widget h2,
.fpw-matchup-widget h3,
.fpw-matchup-widget h4,
.fpw-matchup-widget h5,
.fpw-matchup-widget h6 {
  font-size: 1rem;
  line-height: 1rem;
}
.fpw-cover-container {
  background-color: #ccc;
  width: 100%;
  height: 60px;
  position: relative;
  overflow: hidden;
}
.fpw-away-team {
  width: 90%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 50.1%;
  transform: skewX(-15deg);
  z-index: 20;
}
.fpw-away-team-name {
  text-transform: uppercase;
  text-align: right;
}
.fpw-away-team-logo {
  position: relative;
  z-index: 30;
  text-align: right;
  margin-left: 15px;
  margin-right: 5px;
}
.fpw-away-team-logo img {
  width: 100%;
}

.fpw-home-team {
  width: 90%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50.1%;
  transform: skewX(-15deg);
  z-index: 20;
}
.fpw-home-team-name {
  text-transform: uppercase;
  text-align: left;
}
.fpw-home-team-logo {
  position: relative;
  z-index: 30;
  margin-left: 5px;
  margin-right: 15px;
}
.fpw-home-team-logo img {
  width: 100%;
}

.fpw-team-location {
  font-weight: 100;
}
.fpw-team-nickname {
  font-weight: 600;
}

.fpw-matchup {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.fpw-spacer {
  height: 100%;
  z-index: 100;
}
</style>
