import { createApp, h } from 'vue';
import wrapper from 'vue3-webcomponent-wrapper';
import App from './Widget.vue';

import '@/assets/tailwind.css';
import '@purge-icons/generated';
import WebFont from 'webfontloader';

const fonts = WebFont.load({
  google: {
    families: ['Roboto:300,400,900', 'Kanit:300,400,500,500i,600,900', 'Inconsolata:400,500,600,700'],
  },
  custom: {
    families: ['Abolition'],
    urls: ['https://use.typekit.net/gfg8wdn.css'],
  },
});

const createAppWrapper = (App) => {
  return createApp(App)
  .use(fonts)
}
const widget = wrapper(App, createAppWrapper, h);

window.customElements.define('fpts-widget', widget);
