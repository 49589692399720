<template>
  <div>
    <component v-if="withHeading" :is="tag" :id="headingSlug" :data-title="headingText" class="fpw-odds-heading tw-font-kanit tw-uppercase tw-pt-2">{{ headingText }}</component>
    <fdw-single-game-odds class="odds" client="24" league="nfl" :gameid="matchup.eventId" hidestate="true" hidelogos="false" />
  </div>
</template>

<script>
import slugify from '@fantasypoints/slugify';

export default {
  name: 'Odds',
  props: {
    options: {
      type: Object,
      require: false,
    },
    matchup: {
      type: Object,
      required: true,
    },
    awayTeam: {
      type: Object,
      required: true,
    },
    homeTeam: {
      type: Object,
      required: true,
    },
  },
  computed: {
    singleTeam() {
      if (this.$props.options && this.$props.options.single && this.$props.team) {
        return this.$props.team === this.awayTeam.abbreviation ? this.awayTeam : this.homeTeam;
      }
      return false;
    },
    tag() {
      return `h${this.$props.options && this.$props.options.h ? this.$props.options.h : 3}`;
    },
    headingText() {
      if (this.$props.options && this.$props.options.title) return this.$props.options.title;
      return 'Wager Report';
    },
    headingSlug() {
      let text = `${this.headingText}-${this.$props.awayTeam.fullName}-vs-${this.$props.homeTeam.fullName}`
      if (this.singleTeam) {
        text = `${this.headingText}-${this.singleTeam.fullName}`;
      }
      return `h.${slugify.generate(text)}`;
    },
    withHeading() {
      return !this.$props.options || (this.$props.options && this.$props.options.h && this.$props.options.h !== false);
    },
  },
}
</script>