<template>
  <div class="fpts-widget">
    <div v-if="widget.matchup">
      <Matchup :awayTeam="awayTeam" :homeTeam="homeTeam" :scale="scale" :matchup="matchup" :options="widget.matchup.options" />
    </div>
    <div v-if="widget.odds && matchup">
      <Odds :matchup="matchup" :awayTeam="awayTeam" :homeTeam="homeTeam" :options="widget.odds.options" />
    </div>
    <div v-if="widget.wx && forecast">
      <WeatherReport :forecast="forecast" :awayTeam="awayTeam" :homeTeam="homeTeam" :lastUpdatedDate="matchup.lastUpdatedDate" :options="widget.wx.options" />
    </div>
    <div v-if="widget.injury">
      <InjuryReport :team="team" :awayTeam="awayTeam" :homeTeam="homeTeam" :gameday="matchup.dayOfWeek" :options="widget.injury.options" />
    </div>
  </div>
</template>

<script>
import { version } from '../package';
import { defineComponent } from 'vue';
import { getSeasonType, makeQueryString, getOptions, formatUpdatedDate, formatGameDate } from '@/utils';

import Matchup from '@/components/Matchup.vue';
import Odds from '@/components/Odds.vue';
import WeatherReport from '@/components/WeatherReport.vue';
import InjuryReport from '@/components/InjuryReport.vue';

export default defineComponent({
  name: 'fpts-widget',
  props: {
    widgets: {
      type: String,
      required: true,
    },
    seasonType: {
      type: [String, Number],
      required: true,
    },
    season: {
      type: Number,
      required: true,
    },
    week: {
      type: Number,
      required: true,
    },
    team: {
      type: String,
      required: false,
    },
    away: {
      type: String,
      required: true,
    },
    home: {
      type: String,
      required: true,
    },
  },
  components: {
    Matchup,
    Odds,
    WeatherReport,
    InjuryReport,
  },
  data() {
    return {
      appVersion: version,
      scale: Number(0.1),
      widget: {},
      matchup: null,
      forecast: null,
      awayTeam: null,
      homeTeam: null,
    };
  },
  methods: {
    getWidgets() {
      this.$props.widgets
        .split(',')
        .map((w) => {
          let widget = w;
          
          // Check for options
          let options = widget.match(/\(.*?\)/gm);
          if (options) {
            widget = widget.toString().replace(options[0], '');
            options = options[0].replace('(', '').replace(')', '').split(';');
            options = getOptions(options);
          }

          widget = widget.toLowerCase().trim();
          console.log(widget, options);
          this.widget[widget] = {
            enabled: true,
            options,
          };
        });
    },
    async getData() {
      const qs = makeQueryString({
        week: Number(this.$props.week),
        season: Number(this.$props.season),
        seasonType: getSeasonType(this.$props.seasonType),
        away: this.$props.away,
        home: this.$props.home,
      });
      await fetch(`${process.env.VUE_APP_API_URL}?${qs}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          this.getWidgets();
          this.matchup = data.matchup;
          this.forecast = data.forecast;
          this.awayTeam = data.awayTeam;
          this.homeTeam = data.homeTeam;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  async created() {
    await this.getData();
  },
  mounted() {
    if (!document.getElementById('fpw_version')) {
      const el = document.createElement('div');
      el.id = 'fpw_version';
      el.innerHTML = this.appVersion;
      el.style.cssText = 'display: none;';
      document.body.appendChild(el);
    }
  //   if (typeof fdw_define !== 'function') {
  //     const sdioWidget = document.createElement('script');
  //     sdioWidget.setAttribute('src', 'https://sportsdata.io/assets/widgets/js/widgets.js');
  //     document.head.appendChild(sdioWidget);
  //   }
  },
});
</script>
