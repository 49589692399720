<template>
  <span
    class="icon iconify"
    :data-icon="icon"
    :style="{ width: iconWidth, height: iconHeight }"
  ></span>
</template>

<script>
export default {
  name: 'Icon',
  /**
   * @prop {string} icon - Iconify icon identifier (https://iconify.design/)
   * @prop {string} size - Uniform height and weight. If present, overrides height and width props. (optional)
   * @prop {string} height - Value for height in units (default = 1)
   * @prop {string} width - Value for width in units (default = 1)
   * @prop {string} unit - Unit type. Accepted types: em, rem, px and % (default = em)
   */
  props: {
    icon: String,
    size: String,
    height: {
      type: String,
      default: '1',
    },
    width: {
      type: String,
      default: '1',
    },
    unit: {
      type: String,
      default: 'em',
    },
  },
  computed: {
    iconWidth: function() {
      if (this.size) return `${this.size}${this.unit}`;
      return `${this.width}${this.unit}`;
    },
    iconHeight: function() {
      if (this.size) return `${this.size}${this.unit}`;
      return `${this.height}${this.unit}`;
    },
  },
};
</script>

<style lang="scss">
.icon {
  display: inline-block;
  vertical-align: text-top;

  &svg {
    display: inline-block;
  }
}
</style>
