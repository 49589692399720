import { zonedTimeToUtc, utcToZonedTime, format } from 'date-fns-tz';

export const getSeasonType = (value) => {
  if (Number(value) > 0) return Number(value);
  switch (value) {
    case 'PRE':
      return 2;
    case 'POST':
      return 3;
    case 'REG':
    default:
      return 1;
  }
}

export const makeQueryString = (payload) => {
  // Construct query string from payload
  let queryString = [];
  delete payload.url;
  if (Object.keys(payload).length) {
    queryString = Object.keys(payload)
      .map((key) => `${key}=${payload[key]}`)
      .sort()
      .join('&');
  }
  return queryString;
};

export const getOptions = (payload) => {
  // Split options into a key value pair
  const options = {};
  payload.forEach((item) => {
    let [key, value] = item.split('=');
    if (!value || value === "true") value = true;
    if (value === "false") value = false;
    options[key.toString()] = value;
  });
  return options;
};

export const formatUpdatedDate= (params) => {
  const date = new Date(params.date);
  const timeZone = 'America/New_York';
  const zonedDate = utcToZonedTime(date, timeZone);
  const pattern = 'EEEE, MMMM do, yyyy h:mm a';
  return format(zonedDate, pattern, { timeZone });
};

export const formatGameDate = (params) => {
  const timeZone = 'America/New_York';
  const utcDate = new Date(params.date).toLocaleString('en-US', { timeZone: 'GMT' });
  const date = zonedTimeToUtc(new Date(utcDate), timeZone);
  const zonedDate = utcToZonedTime(date, timeZone);
  const pattern = 'h:mm a';
  return format(zonedDate, pattern, { timeZone });
};

export const mapWeatherIcon = (code, isDay) => {
  switch (Number(code)) {
    // Thunderstorm
    case 200:
    case 201:
    case 202:
    case 210:
    case 211:
    case 212:
    case 221:
    case 230:
    case 231:
    case 232:
    case 1087:
    case 1273:
    case 1276:
      return 'wi:thunderstorm';
    // Drizzle
    case 300:
    case 301:
    case 302:
    case 310:
    case 311:
    case 312:
    case 313:
    case 314:
    case 321:
    case 1072:
    case 1150:
    case 1153:
    case 1168:
    case 1171:
      return 'fluent:weather-drizzle-48-regular';
    // Light Rain
    case 500:
    case 501:
    case 1063:
    case 1180:
    case 1183:
    case 1186:
    case 1189:
    case 1198:
      return 'wi:rain';
    // Heavy Rain
    case 502:
    case 503:
    case 504:
    case 511:
    case 1192:
    case 1195:
    case 1201:
    case 1246:
      return 'wi:rain-wind';
    // Rain Showers
    case 520:
    case 521:
    case 522:
    case 531:
    case 1240:
    case 1243:
      return 'wi:showers';
    // Snow
    case 600:
    case 601:
    case 620:
    case 621:
    case 622:
    case 1066:
    case 1210:
    case 1213:
    case 1216:
    case 1219:
    case 1255:
    case 1258:
    case 1261:
    case 1264:
      return 'wi:snow';
    // Heavy Snow
    case 602:
    case 1114:
    case 1117:
    case 1222:
    case 1225:
    case 1279:
    case 1282:
      return 'wi:snow-wind';
    // Sleet
    case 611:
    case 612:
    case 613:
    case 1069:
    case 1204:
    case 1207:
    case 1237:
    case 1249:
    case 1252:
      return 'wi:sleet';
    // Rain and Snow
    case 615:
    case 616:
      return 'wi:rain-mix';
    // Mist, Smoke, Haze, Fog, Sand, Ash
    case 701:
    case 711:
    case 721:
    case 741:
    case 762:
    case 771:
    case 781:
    case 1030:
    case 1135:
    case 1147:
      return 'wi:fog';
    // Dust
    case 731:
    case 761:
      return 'wi:dust';
    // Clear
    case 800:
    case 1000:
      return isDay ? 'wi:day-sunny' : 'wi:night-clear';
    // Partly Cloudy
    case 801:
    case 1003:
      return isDay ? 'wi:day-cloudy' : 'wi:night-alt-cloudy';
    // Cloudy
    case 802:
    case 1006:
      return 'wi:cloud';
    // Broken/Overcast
    case 803:
    case 804:
    case 1009:
    default:
      return 'wi:cloudy';
  }
}

export default {
  getSeasonType,
  makeQueryString,
  getOptions,
  formatUpdatedDate,
  formatGameDate,
  mapWeatherIcon,
}