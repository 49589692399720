<template>
  <div>
    <component v-if="withHeading" :is="tag" :id="headingSlug" :data-title="headingText" class="fpw-injury-heading tw-font-kanit tw-uppercase tw-pt-2">{{ headingText }}</component>
    <div v-if="withDate" class="fpw-injury-updated-date tw-text-xs tw-italic">{{ lastUpdated }}</div>
    <div class="tw-flex tw-flex-col">
      <div class="tw-w-full tw-mb-2">
        <div v-if="awayTeam.injuryReport && !singleTeam">
          <InjuryReportDetails :team="awayTeam" :options="options" :gameday="gameday" />
        </div>
        <div v-if="homeTeam.injuryReport && !singleTeam">
          <InjuryReportDetails :team="homeTeam" :options="options" :gameday="gameday" />
        </div>
        <div v-if="singleTeam">
          <InjuryReportDetails :team="singleTeam" :options="options" :gameday="gameday" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import slugify from '@fantasypoints/slugify';
import { formatUpdatedDate } from '@/utils';
import InjuryReportDetails from '@/components/InjuryReportDetails.vue';

export default {
  name: 'InjuryReport',
  components: {
    InjuryReportDetails,
  },
  props: {
    awayTeam: {
      type: Object,
      required: true,
    },
    homeTeam: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: false,
    },
    team: {
      type: String,
      required: false,
    },
    gameday: String,
  },
  computed: {
    singleTeam() {
      if (this.$props.options && this.$props.options.single && this.$props.team) {
        return this.$props.team === this.awayTeam.abbreviation ? this.awayTeam : this.homeTeam;
      }
      return false;
    },
    tag() {
      return `h${this.$props.options && this.$props.options.h ? this.$props.options.h : 3}`;
    },
    headingText() {
      if (this.$props.options && this.$props.options.title) return this.$props.options.title;
      return 'Injury Report';
    },
    headingSlug() {
      let text = `${this.headingText}-${this.$props.awayTeam.fullName}-vs-${this.$props.homeTeam.fullName}`
      if (this.singleTeam) {
        text = `${this.headingText}-${this.singleTeam.fullName}`;
      }
      return `h.${slugify.generate(text)}`;
    },
    withHeading() {
      return !this.$props.options || (this.$props.options && this.$props.options.h && this.$props.options.h !== false);
    },
    withDate() {
      if (this.$props.options && this.$props.options.withDate === false) return false;
      return true;
    },
    lastUpdated() {
      if (this.awayTeam.injuryReport && this.awayTeam.injuryReport[0] && this.awayTeam.injuryReport[0].lastUpdatedDate) {
        return `Updated ${formatUpdatedDate({ date: this.awayTeam.injuryReport[0].lastUpdatedDate })} ET`;
      }
      return '';
    },
  },
};
</script>
